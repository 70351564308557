import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCourseDetail, enrollInCourse, checkEnrollmentStatus } from '../services/api';
import { AuthContext } from '../context/AuthContext';
import { Star, Clock, Users, Calendar, ChevronDown, ChevronUp } from 'lucide-react';

const CourseLandingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [course, setCourse] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedModule, setExpandedModule] = useState(null);
  const [enrolling, setEnrolling] = useState(false);

  useEffect(() => {
    const loadCourseAndEnrollmentStatus = async () => {
      try {
        const [fetchedCourse, enrollmentStatus] = await Promise.all([
          fetchCourseDetail(id),
          isAuthenticated ? checkEnrollmentStatus(id) : false
        ]);
        setCourse(fetchedCourse);
        setIsEnrolled(enrollmentStatus);
      } catch (err) {
        console.error('Error loading course details or enrollment status:', err);
        setError('Failed to load course details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadCourseAndEnrollmentStatus();
  }, [id, isAuthenticated]);

  const handleEnroll = async () => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: `/courses/${id}` } });
      return;
    }

    setEnrolling(true);
    try {
      await enrollInCourse(id);
      setIsEnrolled(true);
      alert('Successfully enrolled in the course!');
      navigate(`/courses/${id}/content`);
    } catch (err) {
      console.error('Error enrolling in course:', err);
      alert('Failed to enroll in the course. Please try again.');
    } finally {
      setEnrolling(false);
    }
  };

  const toggleModule = (index) => {
    setExpandedModule(expandedModule === index ? null : index);
  };

  const getYoutubeVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  if (loading) return <div className="text-center text-white">Loading course details...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!course) return <div className="text-center text-white">Course not found.</div>;

  const youtubeVideoId = course.promoVideoURL ? getYoutubeVideoId(course.promoVideoURL) : null;

  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <h1 className="text-4xl font-bold mb-4">{course.title}</h1>
            <p className="text-xl mb-6">{course.description}</p>
            
            <div className="flex items-center mb-6 text-gray-300">
              <Star className="text-yellow-400 mr-1" />
              <span>{course.rating} ({course.reviewCount} reviews)</span>
              <Users className="ml-4 mr-1" />
              <span>{course.enrollmentCount} students enrolled</span>
              <Calendar className="ml-4 mr-1" />
              <span>Last updated {new Date(course.lastUpdated).toLocaleDateString()}</span>
            </div>

            {youtubeVideoId && (
              <div className="mb-8">
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                  title="Course Promo Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}

            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">What you'll learn</h2>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {course.learningObjectives.map((objective, index) => (
                  <li key={index} className="flex items-start">
                    <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                    <span>{objective}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Course Content</h2>
              {course.curriculum.map((module, index) => (
                <div key={index} className="mb-4 border border-gray-700 rounded">
                  <button
                    className="flex justify-between items-center w-full p-4 text-left bg-gray-800 hover:bg-gray-700 transition-colors"
                    onClick={() => toggleModule(index)}
                  >
                    <span className="font-semibold">{module.title}</span>
                    {expandedModule === index ? <ChevronUp /> : <ChevronDown />}
                  </button>
                  {expandedModule === index && (
                    <ul className="p-4 bg-gray-800">
                      {module.lessons.map((lesson, lessonIndex) => (
                        <li key={lessonIndex} className="mb-2 flex items-center">
                          <Clock className="mr-2 w-4 h-4" />
                          <span>{lesson.title} ({lesson.duration})</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Prerequisites</h2>
              <ul className="list-disc pl-5">
                {course.prerequisites.map((prerequisite, index) => (
                  <li key={index}>{prerequisite}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="md:col-span-1">
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg sticky top-8">
              <div className="text-4xl font-bold mb-4">${course.price.amount}</div>
              {isEnrolled ? (
                <button 
                  onClick={() => navigate(`/courses/${id}/content`)}
                  className="w-full bg-green-500 text-white py-3 rounded-lg font-semibold hover:bg-green-600 transition duration-300"
                >
                  Go to Course Content
                </button>
              ) : (
                <button 
                  className="w-full bg-indigo-600 text-white py-3 rounded-lg font-semibold hover:bg-indigo-700 transition duration-300"
                  onClick={handleEnroll}
                  disabled={enrolling}
                >
                  {enrolling ? 'Enrolling...' : 'Enroll Now'}
                </button>
              )}
              <div className="mt-6 space-y-4 text-sm">
                <div className="flex items-center">
                  <Clock className="mr-2" />
                  <span>{course.duration} total length</span>
                </div>
                <div className="flex items-center">
                  <Star className="mr-2" />
                  <span>{course.level} level</span>
                </div>
                <div className="flex items-center">
                  <Users className="mr-2" />
                  <span>{course.enrollmentCount} students enrolled</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLandingPage;

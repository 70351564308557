import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import CourseCard from './CourseCard';
import { fetchCourses, fetchEnrolledCourses } from '../services/api';
import { AuthContext } from '../context/AuthContext';
import debounce from 'lodash/debounce';

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const searchInputRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedDifficulty, setSelectedDifficulty] = useState('');
  const [selectedDuration, setSelectedDuration] = useState('');

  const loadCourses = useCallback(async (filters) => {
    try {
      setLoading(true);
      const fetchedCourses = await fetchCourses(filters);
      setCourses(fetchedCourses);

      if (isAuthenticated) {
        const fetchedEnrolledCourses = await fetchEnrolledCourses();
        setEnrolledCourses(fetchedEnrolledCourses);
      }
    } catch (err) {
      console.error('Error loading courses:', err);
      setError('Failed to load courses. Please ensure the backend is running and try again.');
    } finally {
      setLoading(false);
      // Refocus the search input after loading completes
      searchInputRef.current?.focus();
    }
  }, [isAuthenticated]);

  const debouncedLoadCourses = useCallback(
    debounce((filters) => loadCourses(filters), 300),
    [loadCourses]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchFromQuery = queryParams.get('search');
    if (searchFromQuery) {
      setSearchQuery(searchFromQuery);
    }
    // Focus the search input on component mount
    searchInputRef.current?.focus();
  }, [location.search]);

  useEffect(() => {
    debouncedLoadCourses({
      search: searchQuery,
      topic: selectedTopic,
      difficulty: selectedDifficulty,
      duration: selectedDuration
    });
  }, [debouncedLoadCourses, searchQuery, selectedTopic, selectedDifficulty, selectedDuration]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getCourseWithProgress = (course) => {
    const enrolledCourse = enrolledCourses.find(ec => ec.id === course.id);
    return enrolledCourse ? { ...course, progress: enrolledCourse.progress } : course;
  };

  // This effect ensures the search input is always focused
  useEffect(() => {
    searchInputRef.current?.focus();
  });

  if (loading) return <div className="text-center mt-8">Loading courses...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <div className="bg-gray-100 min-h-screen py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Course Catalog</h2>
        
        {/* Filtering options */}
        <div className="mb-6 flex flex-wrap gap-4">
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search courses..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="flex-grow px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            autoFocus // Add autoFocus attribute
          />
          <select
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(e.target.value)}
            className="px-4 py-2 border rounded-md"
          >
            <option value="">All Topics</option>
            <option value="ai">AI</option>
            <option value="web-development">Web Development</option>
            <option value="data-science">Data Science</option>
          </select>
          <select
            value={selectedDifficulty}
            onChange={(e) => setSelectedDifficulty(e.target.value)}
            className="px-4 py-2 border rounded-md"
          >
            <option value="">All Difficulties</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
          <select
            value={selectedDuration}
            onChange={(e) => setSelectedDuration(e.target.value)}
            className="px-4 py-2 border rounded-md"
          >
            <option value="">All Durations</option>
            <option value="short">Short (&lt; 2 weeks)</option>
            <option value="medium">Medium (2-4 weeks)</option>
            <option value="long">Long (&gt; 4 weeks)</option>
          </select>
        </div>

        {courses.length === 0 ? (
          <p>No courses available matching your criteria.</p>
        ) : (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {courses.map(course => (
              <CourseCard
                key={course.id}
                course={getCourseWithProgress(course)}
                showProgress={isAuthenticated && enrolledCourses.some(ec => ec.id === course.id)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Courses;

// src/components/Home.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CourseCard from './CourseCard';
import TestimonialCard from './TestimonialCard';

const Home = () => {
  useEffect(() => {
    console.log('Home component mounted');
  }, []);

  console.log('Home component rendering');

  const featuredCourses = [
    {
      id: 1,
      title: "Doctor of Business Administration",
      university: "Golden Gate University",
      duration: "36 Months",
      level: "Doctorate",
      image: "/api/placeholder/400/300",
    },
    {
      id: 2,
      title: "Master of Science in Data Science",
      university: "Liverpool John Moores University",
      duration: "24 Months",
      level: "Master's",
      image: "/api/placeholder/400/300",
    },
    {
      id: 3,
      title: "Master of Business Administration",
      university: "Edgewood College",
      duration: "18 Months",
      level: "Master's",
      image: "/api/placeholder/400/300",
    },
  ];

  const testimonials = [
    {
      id: 1,
      quote: "The reason I chose AI Guru is that I can study in my free time, which allows me to focus on my work and family commitments.",
      name: "Jane Doe",
      role: "Data Science Student",
      image: "/api/placeholder/80/80",
    },
    {
      id: 2,
      quote: "I find that the program is well organized and the lectures are engaging. The hands-on projects have been invaluable.",
      name: "John Smith",
      role: "MBA Student",
      image: "/api/placeholder/80/80",
    },
    {
      id: 3,
      quote: "The program's ease of use truly made lifelong learning accessible for me. I've gained skills I use daily in my job.",
      name: "Emily Brown",
      role: "DBA Graduate",
      image: "/api/placeholder/80/80",
    },
  ];


  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-indigo-800">
        <div className="absolute inset-0">
          <img className="w-full h-full object-cover" src="/api/placeholder/1600/900" alt="AI learning environment" />
          <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Change the course of your career at your pace</h1>
          <p className="mt-6 text-xl text-indigo-100 max-w-3xl">Upskill Today with AI Guru! Explore degrees, certificates, and bootcamps designed to propel your career forward.</p>
          <div className="mt-10 max-w-sm sm:max-w-none sm:flex">
            <Link to="/courses" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8">Explore Courses</Link>
          </div>
        </div>
      </div>


      {/* Statistics Section */}
      <div className="bg-indigo-800 py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Trusted by learners worldwide
            </h2>
            <p className="mt-3 text-xl text-indigo-200 sm:mt-4">
              Join our global community of learners and transform your career
            </p>
          </div>
          <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">
                Learners Enrolled
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                2+ Million
              </dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">
                Countries Represented
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                100+
              </dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-indigo-200">
                Positive Career Impact
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                80%
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Featured Courses Section */}
      <div className="bg-white py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Discover Our Courses
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Explore advanced degrees and specialized courses designed to propel your career forward
            </p>
          </div>
          <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {featuredCourses.map((course) => (
              <CourseCard key={course.id} course={course} />
            ))}
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-gray-50 py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Real People, Real Stories
            </h2>
          </div>
          <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {testimonials.map((testimonial) => (
              <TestimonialCard key={testimonial.id} testimonial={testimonial} />
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="bg-indigo-700">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Ready to dive in?</span>
            <span className="block">Start your learning journey today.</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-indigo-200">
            Explore our courses and take the first step towards your dream career.
          </p>
          <Link
            to="/courses"
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
          >
            Get started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;

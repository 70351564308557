import React from 'react';

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <p className="text-sm text-gray-500">"{testimonial.quote}"</p>
        <div className="mt-4 flex items-center">
          <div className="flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={testimonial.image} alt={testimonial.name} />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">{testimonial.name}</p>
            <p className="text-sm text-gray-500">{testimonial.role}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchEnrolledCourses, getProgress } from '../services/api';

const CourseCard = ({ course }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const loadProgress = async () => {
      try {
        const userProgress = await getProgress(course.id);
        setProgress(userProgress.progress);
      } catch (error) {
        console.error('Error loading progress:', error);
      }
    };
    loadProgress();
  }, [course.id]);

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-xl font-bold mb-2">{course.title}</h3>
        <p className="text-gray-600 mb-2">Instructor: {course.instructor}</p>
        <p className="text-gray-600 mb-2">Duration: {course.duration}</p>
        <div className="mb-4">
          <p className="text-sm font-semibold mb-1">Progress: {progress}%</p>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
        <Link
          to={`/courses/${course.id}/content/0`}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {progress > 0 ? 'Continue Course' : 'Start Course'}
        </Link>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadEnrolledCourses = async () => {
      try {
        setLoading(true);
        const courses = await fetchEnrolledCourses();
        setEnrolledCourses(courses);
      } catch (err) {
        console.error('Error loading enrolled courses:', err);
        setError('Failed to load enrolled courses. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadEnrolledCourses();
  }, []);

  if (loading) return <div className="text-center py-8">Loading your courses...</div>;
  if (error) return <div className="text-center py-8 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">My Dashboard</h1>
      <h2 className="text-2xl font-semibold mb-4">Enrolled Courses</h2>
      {enrolledCourses.length === 0 ? (
        <p className="text-gray-600">You are not enrolled in any courses yet.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {enrolledCourses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronDown, ChevronLeft } from 'lucide-react';

const CollapsibleSidebar = ({ course, currentSectionIndex, currentLectureIndex, onNavigate }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openSection, setOpenSection] = useState(currentSectionIndex);

  useEffect(() => {
    setOpenSection(currentSectionIndex);
  }, [currentSectionIndex]);

  const toggleSection = (sectionIndex) => {
    setOpenSection(prevOpenSection => (prevOpenSection === sectionIndex ? null : sectionIndex));
  };

  return (
    <div
      className={`relative transition-all duration-300 ease-in-out ${
        isCollapsed ? 'w-8' : 'w-64'
      } h-full overflow-y-auto`}
    >
      <div className={`h-full bg-gray-100 ${isCollapsed ? 'w-8' : 'w-64'}`}>
        {!isCollapsed && (
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">{course.title}</h2>
            <ul>
              {course.content.map((section, sectionIndex) => (
                <li key={sectionIndex} className="mb-2">
                  <button
                    onClick={() => toggleSection(sectionIndex)}
                    className="flex items-center justify-between w-full text-left font-semibold focus:outline-none hover:bg-gray-200 p-2 rounded cursor-pointer"
                  >
                    <span>{section.title}</span>
                    {openSection === sectionIndex ? (
                      <ChevronDown size={16} />
                    ) : (
                      <ChevronRight size={16} />
                    )}
                  </button>
                  {openSection === sectionIndex && (
                    <ul className="mt-2 pl-4 border-l border-gray-300">
                      {section.lectures.map((lecture, lectureIndex) => (
                        <li key={lectureIndex} className="mb-1">
                          <button
                            onClick={() => onNavigate(sectionIndex, lectureIndex)}
                            className={`block w-full text-left p-2 rounded cursor-pointer ${
                              sectionIndex === currentSectionIndex &&
                              lectureIndex === currentLectureIndex
                                ? 'bg-blue-500 text-white'
                                : 'hover:bg-gray-200'
                            }`}
                          >
                            {lectureIndex + 1}. {lecture.title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="absolute top-2 -right-4 bg-gray-300 rounded-full p-1 hover:bg-gray-400 focus:outline-none"
      >
        {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
      </button>
    </div>
  );
};

export default CollapsibleSidebar;


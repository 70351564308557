import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(
  async (config) => {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken.toString()}`;
      }
    } catch (error) {
      console.error('Error fetching auth session:', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const enrollInCourse = async (courseId) => {
  try {
    const response = await api.post(`/courses/${courseId}/enroll`);
    return response.data;
  } catch (error) {
    console.error('Error enrolling in course:', error);
    throw error;
  }
};

export const fetchEnrolledCourses = async () => {
  try {
    const response = await api.get('/courses/enrolled');
    const enrolledCourses = response.data;

    const coursesWithProgress = await Promise.all(
      enrolledCourses.map(async (course) => {
        const progressResponse = await api.get(`/courses/${course.id}/progress`);
        return { ...course, progress: progressResponse.data.progress };
      })
    );

    return coursesWithProgress;
  } catch (error) {
    console.error('Error fetching enrolled courses:', error);
    throw error;
  }
};

export const fetchCourses = async (filters = {}) => {
  try {
    console.log('Fetching courses with filters:', filters);
    const response = await api.get('/courses', { params: filters });
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
};

export const fetchCourseDetail = async (courseId) => {
  try {
    const response = await api.get(`/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching course detail:', error);
    throw error;
  }
};

export const fetchCourseContent = async (courseId) => {
  try {
    const response = await api.get(`/courses/${courseId}/content`);
    return response.data;
  } catch (error) {
    console.error('Error fetching course content:', error);
    throw error;
  }
};

export const updateCourseProgress = async (courseId, progress, completedLecture) => {
  try {
    const response = await api.post(`/courses/${courseId}/progress`, { progress, completedLecture });
    return response.data;
  } catch (error) {
    console.error('Error updating course progress:', error);
    throw error;
  }
};


export const checkEnrollmentStatus = async (courseId) => {
  try {
    const response = await api.get(`/courses/${courseId}/enrollment-status`);
    return response.data.isEnrolled;
  } catch (error) {
    console.error('Error checking enrollment status:', error);
    return false;
  }
};

export const fetchVideoUrl = async (s3Key) => {
  try {
    console.log('Fetching video URL for:', s3Key);
    const response = await api.get(`/courses/video/${encodeURIComponent(s3Key)}`);
    console.log('Video URL response:', response.data);
    return response.data.url;
  } catch (error) {
    console.error('Error fetching video URL:', error);
    throw error;
  }
};

export const updateProgress = async (courseId, progress) => {
  try {
    const response = await api.post(`/courses/${courseId}/progress`, { progress });
    return response.data;
  } catch (error) {
    console.error('Error updating progress:', error);
    throw error;
  }
};

export const getProgress = async (courseId) => {
  try {
    const response = await api.get(`/courses/${courseId}/progress`);
    return response.data;
  } catch (error) {
    console.error('Error fetching progress:', error);
    throw error;
  }
};

export const fetchRecommendedCourses = async () => {
  try {
    const response = await api.get('/courses/recommendations');
    return response.data;
  } catch (error) {
    console.error('Error fetching recommended courses:', error);
    throw error;
  }
};

export const fetchUserProfile = async () => {
  try {
    const response = await api.get('/user/profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const updateUserProfile = async (profileData) => {
  try {
    const response = await api.put('/user/profile', profileData);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const uploadProfilePicture = async (file) => {
  try {
    const formData = new FormData();
    formData.append('profilePicture', file);
    const response = await api.post('/user/profile-picture', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    throw error;
  }
};

export const submitLearningPathQuestionnaire = async (questionnaireData) => {
  try {
    const response = await api.post('/user/learning-path-questionnaire', questionnaireData);
    return response.data;
  } catch (error) {
    console.error('Error submitting learning path questionnaire:', error);
    throw error;
  }
};


export const fetchQuestionnaire = async () => {
  try {
    const response = await api.get('/user/questionnaire');
    return response.data;
  } catch (error) {
    console.error('Error fetching questionnaire:', error);
    throw error;
  }
};

export const submitQuestionnaire = async (questionnaireData) => {
  try {
    const response = await api.post('/user/learning-path-questionnaire', questionnaireData);
    return response.data;
  } catch (error) {
    console.error('Error submitting questionnaire:', error);
    throw error;
  }
};

export const startNotebook = async () => {
  try {
    const response = await api.post('/courses/start-notebook');
    return response.data;
  } catch (error) {
    console.error('Error starting notebook:', error);
    throw error;
  }
};

export const stopNotebook = async (containerId) => {
  try {
    const response = await api.post('/courses/stop-notebook', { containerId });
    return response.data;
  } catch (error) {
    console.error('Error stopping notebook:', error);
    throw error;
  }
};


export const createBookmark = async (courseId, lessonIndex, timestamp) => {
  const response = await api.post(`/courses/${courseId}/bookmarks`, { lessonIndex, timestamp });
  return response.data;
};

export const deleteBookmark = async (bookmarkId) => {
  await api.delete(`/bookmarks/${bookmarkId}`);
};

export const fetchBookmarks = async (courseId) => {
  const response = await api.get(`/courses/${courseId}/bookmarks`);
  return response.data;
};

export const fetchNotes = async (courseId, lessonIndex) => {
  const response = await api.get(`/courses/${courseId}/notes/${lessonIndex}`);
  return response.data;
};

export const saveNote = async (courseId, lessonIndex, content) => {
  const response = await api.post(`/courses/${courseId}/notes/${lessonIndex}`, { content });
  return response.data;
};

export default api;

// src/components/CourseDetail.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCourseDetail, enrollInCourse, checkEnrollmentStatus } from '../services/api';
import { AuthContext } from '../context/AuthContext';

const CourseDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [course, setCourse] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enrolling, setEnrolling] = useState(false);

  useEffect(() => {
    const loadCourseAndEnrollmentStatus = async () => {
      try {
        const fetchedCourse = await fetchCourseDetail(id);
        setCourse(fetchedCourse);
        if (isAuthenticated) {
          const enrollmentStatus = await checkEnrollmentStatus(id);
          setIsEnrolled(enrollmentStatus);
        }
      } catch (err) {
        console.error('Error loading course details or enrollment status:', err);
        setError('Failed to load course details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadCourseAndEnrollmentStatus();
  }, [id, isAuthenticated]);

  const handleEnroll = async () => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: `/courses/${id}` } });
      return;
    }

    setEnrolling(true);
    try {
      await enrollInCourse(id);
      setIsEnrolled(true);
      alert('Successfully enrolled in the course!');
      navigate(`/courses/${id}/content`);  // Navigate to course content after enrollment
    } catch (err) {
      console.error('Error enrolling in course:', err);
      alert('Failed to enroll in the course. Please try again.');
    } finally {
      setEnrolling(false);
    }
  };

  if (loading) return <div className="text-center">Loading course details...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!course) return <div className="text-center">Course not found.</div>;

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold mb-6">{course.title}</h2>
      <div className="bg-white shadow-md rounded-lg overflow-hidden p-6">
        <p className="text-gray-600 mb-4">Instructor: {course.instructor}</p>
        <p className="text-gray-600 mb-4">Duration: {course.duration}</p>
        <h3 className="text-xl font-semibold mb-2">Description</h3>
        <p className="mb-4">{course.description}</p>
        {course.syllabus && course.syllabus.length > 0 && (
          <>
            <h3 className="text-xl font-semibold mb-2">Syllabus</h3>
            <ul className="list-disc pl-5">
              {course.syllabus.map((item, index) => (
                <li key={index} className="mb-2">{item}</li>
              ))}
            </ul>
          </>
        )}
      </div>
      {isEnrolled ? (
        <button
          onClick={() => navigate(`/courses/${id}/content`)}
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Go to Course Content
        </button>
      ) : (
        <button
          onClick={handleEnroll}
          disabled={enrolling}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400"
        >
          {enrolling ? 'Enrolling...' : 'Enroll in Course'}
        </button>
      )}
    </div>
  );
};

export default CourseDetail;

// src/App.js
import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Home from './components/Home';
import Courses from './components/Courses';
import CourseDetail from './components/CourseDetail';
import CourseContent from './components/CourseContent';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Layout from './components/Layout';
import { AuthProvider, AuthContext } from './context/AuthContext';
import CourseLandingPage from './components/CourseLandingPage';
import './index.css';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_CrO0p0Qtt',
      userPoolClientId: '19c03fta9gj3tleb507l235io0',
      signUpVerificationMethod: 'code',
      loginWith: {
        email: true,
        phone: false,
        username: false
      },
      socialProviders: ['Google']
    },
    oauth: {
      domain: 'peda-stage.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: 'https://xyz.aistrat.app/',
      redirectSignOut: 'https://xyz.aistrat.app/',
      responseType: 'code'
    }
  }
});

function AuthStateListener() {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setIsAuthenticated(true);
      const isFirstLogin = !localStorage.getItem('hasLoggedIn');
      if (isFirstLogin) {
        localStorage.setItem('hasLoggedIn', 'true');
        navigate('/dashboard');
      }
    } else {
      setIsAuthenticated(false);
      localStorage.removeItem('hasLoggedIn');
    }
  }, [authStatus, setIsAuthenticated, navigate]);

  return null;
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <Authenticator.Provider>
          <Layout>
            <AuthStateListener />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:id" element={<CourseLandingPage />} />
              <Route path="/courses/:id/enroll" element={<CourseDetail />} />

              {/* Updated Routes for CourseContent */}
              <Route path="/courses/:courseId/content/:sectionIndex/:lectureIndex" element={<CourseContent />} />
              <Route path="/courses/:courseId/content/:sectionIndex" element={<CourseContent />} />
              <Route path="/courses/:courseId/content" element={<CourseContent />} />

              {/* Other Routes */}
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/login"
                element={
                  <Authenticator>
                    {({ signOut, user }) => (
                      <Home />
                    )}
                  </Authenticator>
                }
              />
              {/* Fallback Route */}
              <Route path="*" element={<Home />} />
            </Routes>
          </Layout>
        </Authenticator.Provider>
      </AuthProvider>
    </Router>
  );
}

export default App;


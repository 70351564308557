import React from 'react';
import { Popover } from '@headlessui/react';

const Tooltip = ({ children, content }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as="div">
            {children}
          </Popover.Button>

          <Popover.Panel
            static
            className={`absolute z-10 bg-gray-800 text-white text-sm py-1 px-2 rounded shadow-lg ${
              open ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-200 ease-in-out`}
          >
            {content}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default Tooltip;

import React, { useState, useEffect, useContext } from 'react';
import { X, MessageSquare, Terminal, Book } from 'lucide-react';
import { AuthContext } from '../context/AuthContext';
import { startNotebook, stopNotebook } from '../services/api';

const SidePanel = ({ isOpen, onClose, courseId, lessonIndex }) => {
  const [activeTab, setActiveTab] = useState('chat');
  const [notebookUrl, setNotebookUrl] = useState('');
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (activeTab === 'notebook' && isAuthenticated) {
      startNotebookSession();
    }
  }, [activeTab, isAuthenticated]);

  const startNotebookSession = async () => {
    try {
      const { url, token } = await startNotebook();
      setNotebookUrl(`${url}?token=${token}`);
    } catch (error) {
      console.error('Error starting notebook:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'chat':
        return <iframe src="https://claude.ai" className="w-full h-full border-none" />;
      case 'notebook':
        return isAuthenticated ? (
          notebookUrl ? (
            <iframe src={notebookUrl} className="w-full h-full border-none" />
          ) : (
            <div>Loading notebook...</div>
          )
        ) : (
          <div>Please log in to access notebooks.</div>
        );
      case 'terminal':
        return <iframe src="/terminal" className="w-full h-full border-none" />;
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed right-0 top-0 h-full w-1/2 bg-white shadow-lg z-50 flex flex-col">
      <div className="flex justify-between items-center p-4 border-b">
        <div className="flex space-x-4">
          <button
            onClick={() => setActiveTab('chat')}
            className={`p-2 ${activeTab === 'chat' ? 'bg-gray-200' : ''}`}
          >
            <MessageSquare size={20} />
          </button>
          <button
            onClick={() => setActiveTab('notebook')}
            className={`p-2 ${activeTab === 'notebook' ? 'bg-gray-200' : ''}`}
          >
            <Book size={20} />
          </button>
          <button
            onClick={() => setActiveTab('terminal')}
            className={`p-2 ${activeTab === 'terminal' ? 'bg-gray-200' : ''}`}
          >
            <Terminal size={20} />
          </button>
        </div>
        <button onClick={onClose} className="p-2">
          <X size={20} />
        </button>
      </div>
      <div className="flex-grow overflow-hidden">
        {renderContent()}
      </div>
    </div>
  );
};

export default SidePanel;

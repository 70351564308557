import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BookmarkIcon, TrashIcon, Maximize2, Minimize2 } from 'lucide-react';
import { fetchCourseContent, updateCourseProgress, getProgress, createBookmark, deleteBookmark, fetchBookmarks, fetchVideoUrl } from '../services/api';
import CollapsibleSidebar from './CollapsibleSidebar';
import SidePanel from './SidePanel';
import Notes from './Notes';
import Tooltip from './Tooltip';

const CourseContent = () => {
  const { courseId, sectionIndex = 0, lectureIndex = 0 } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(parseInt(sectionIndex));
  const [currentLectureIndex, setCurrentLectureIndex] = useState(parseInt(lectureIndex));
  const [currentLecture, setCurrentLecture] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const loadCourseContent = async () => {
      try {
        setLoading(true);
        const content = await fetchCourseContent(courseId);
        console.log('Fetched course content:', content);
        setCourse(content);
        const userProgress = await getProgress(courseId);
        setProgress(userProgress.progress);
        setLoading(false);
      } catch (err) {
        console.error('Error loading course content:', err);
        setError('Failed to load course content. Please try again later.');
        setLoading(false);
      }
    };

    loadCourseContent();
    loadBookmarks();
  }, [courseId]);

  useEffect(() => {
    if (course) {
      const section = course.content[currentSectionIndex];
      if (section) {
        const lecture = section.lectures[currentLectureIndex];
        setCurrentLecture(lecture);
        if (lecture && lecture.type === 'video') {
          loadVideoUrl(lecture.s3Key);
        } else {
          setVideoUrl(null);
        }
      }
    }
  }, [course, currentSectionIndex, currentLectureIndex]);

  const loadVideoUrl = async (s3Key) => {
    try {
      const url = await fetchVideoUrl(s3Key);
      setVideoUrl(url);
    } catch (error) {
      console.error('Error loading video URL:', error);
      setVideoUrl(null);
    }
  };

  const navigateToLecture = (newSectionIndex, newLectureIndex) => {
    setCurrentSectionIndex(newSectionIndex);
    setCurrentLectureIndex(newLectureIndex);
    navigate(`/courses/${courseId}/content/${newSectionIndex}/${newLectureIndex}`);
  };

  const handleLessonComplete = async () => {
    const totalLectures = course.content.reduce((total, section) => total + section.lectures.length, 0);
    const completedLectures = course.content.slice(0, currentSectionIndex).reduce((total, section) => total + section.lectures.length, 0) + currentLectureIndex + 1;
    const newProgress = Math.round((completedLectures / totalLectures) * 100);
    try {
      await updateCourseProgress(courseId, newProgress, {
        sectionIndex: currentSectionIndex,
        lectureIndex: currentLectureIndex
      });
      setProgress(newProgress);
      navigateToNextLecture();
    } catch (error) {
      console.error('Error updating progress:', error);
    }
  };

  const navigateToNextLecture = () => {
    const currentSection = course.content[currentSectionIndex];
    if (currentLectureIndex < currentSection.lectures.length - 1) {
      navigateToLecture(currentSectionIndex, currentLectureIndex + 1);
    } else if (currentSectionIndex < course.content.length - 1) {
      navigateToLecture(currentSectionIndex + 1, 0);
    } else {
      console.log('Course completed');
      // Optionally, navigate to a course completion page
      // navigate(`/courses/${courseId}/completed`);
    }
  };

  const loadBookmarks = async () => {
    try {
      const fetchedBookmarks = await fetchBookmarks(courseId);
      setBookmarks(fetchedBookmarks);
    } catch (error) {
      console.error('Error loading bookmarks:', error);
    }
  };

  const handleBookmark = async () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      try {
        const newBookmark = await createBookmark(courseId, currentLectureIndex, currentTime);
        setBookmarks([...bookmarks, newBookmark]);
      } catch (error) {
        console.error('Error creating bookmark:', error);
      }
    }
  };

  const handleDeleteBookmark = async (bookmarkId) => {
    try {
      await deleteBookmark(bookmarkId);
      setBookmarks(bookmarks.filter(bookmark => bookmark.id !== bookmarkId));
    } catch (error) {
      console.error('Error deleting bookmark:', error);
    }
  };

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  if (loading) return <div className="text-center">Loading course content...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!course) return <div className="text-center">Course not found.</div>;

  return (
    <div className="flex flex-col h-screen">
      <div className="bg-gray-200 p-4">
	          <h1 className="text-2xl font-bold">{course.title}</h1>
        <div className="w-full bg-gray-300 rounded-full h-2.5 mt-2">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
      <div className="flex flex-1 overflow-hidden">
        <CollapsibleSidebar
          course={course}
          currentSectionIndex={currentSectionIndex}
          currentLectureIndex={currentLectureIndex}
          onNavigate={navigateToLecture}
        />
        <div className={`w-full h-full overflow-y-auto p-8 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-thumb-rounded ${isSidePanelOpen ? 'pr-[50%]' : ''}`}>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">
              {currentLecture?.title || 'Lecture Title'}
            </h2>
            <div className="flex space-x-2">
              <Tooltip content="Toggle Side Panel">
                <button
                  onClick={toggleSidePanel}
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  {isSidePanelOpen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
                </button>
              </Tooltip>
              <Tooltip content="Toggle Fullscreen">
                <button
                  onClick={toggleFullScreen}
                  className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                >
                  {isFullScreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
                </button>
              </Tooltip>
            </div>
          </div>
          {currentLecture?.type === 'video' && videoUrl ? (
            <div className="mb-4">
              <div className="relative">
                <video
                  ref={videoRef}
                  controls
                  className="w-full"
                  onEnded={handleLessonComplete}
                  key={videoUrl}
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="group relative">
                  <Tooltip content="Bookmark Current Time">
                    <button
                      onClick={handleBookmark}
                      className="absolute bottom-4 left-4 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >
                      <BookmarkIcon size={20} />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="mt-4">
                <h3 className="text-xl font-semibold mb-2">Bookmarks</h3>
                {bookmarks
                  .filter(b => b.lectureIndex === currentLectureIndex)
                  .map(bookmark => (
                    <div key={bookmark.id} className="flex items-center justify-between mb-2 bg-gray-100 p-2 rounded">
                      <button
                        onClick={() => {
                          if (videoRef.current) {
                            videoRef.current.currentTime = bookmark.timestamp;
                          }
                        }}
                        className="text-blue-500 hover:underline"
                      >
                        {new Date(bookmark.timestamp * 1000).toISOString().substr(11, 8)}
                      </button>
                      <button
                        onClick={() => handleDeleteBookmark(bookmark.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <TrashIcon size={18} />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
        <div className="mb-4">No valid video found for this lecture. Lecture type: {currentLecture?.type}</div>
          )}
          {currentLecture?.type === 'quiz' && (
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Quiz</h3>
              {/* Implement quiz component here */}
            </div>
          )}
          <Notes courseId={course.id} sectionIndex={currentSectionIndex} lectureIndex={currentLectureIndex} />
          <div className="mt-4">
            <button
              onClick={handleLessonComplete}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            >
              Mark as Complete and Continue
            </button>
          </div>
        </div>
        <SidePanel
          isOpen={isSidePanelOpen}
          onClose={() => setIsSidePanelOpen(false)}
          courseId={courseId}
          sectionIndex={currentSectionIndex}
          lectureIndex={currentLectureIndex}
        />
      </div>
    </div>
  );
};

export default CourseContent;

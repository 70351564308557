import React, { createContext, useState, useEffect } from 'react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInstructor, setIsInstructor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuthState();
  }, []);

  async function checkAuthState() {
    try {
      const currentUser = await getCurrentUser();
      setIsAuthenticated(true);

      // Fetch user attributes to check for instructor status
      const attributes = await fetchUserAttributes();
      setIsInstructor(attributes['custom:isInstructor'] === 'true');
    } catch (err) {
      setIsAuthenticated(false);
      setIsInstructor(false);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      setIsAuthenticated, 
      isInstructor, 
      setIsInstructor 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

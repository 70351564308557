import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fetchNotes, saveNote } from '../services/api';

const Notes = ({ courseId, lessonIndex }) => {
  const [notes, setNotes] = useState('');

  useEffect(() => {
    loadNotes();
  }, [courseId, lessonIndex]);

  const loadNotes = async () => {
    try {
      const fetchedNotes = await fetchNotes(courseId, lessonIndex);
      setNotes(fetchedNotes.content || '');
    } catch (error) {
      console.error('Error loading notes:', error);
    }
  };

  const handleNotesChange = (content) => {
    setNotes(content);
  };

  const handleSaveNotes = async () => {
    try {
      await saveNote(courseId, lessonIndex, notes);
      alert('Notes saved successfully!');
    } catch (error) {
      console.error('Error saving notes:', error);
      alert('Failed to save notes. Please try again.');
    }
  };

  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Notes</h3>
      <ReactQuill 
        value={notes} 
        onChange={handleNotesChange}
        modules={{
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
          ],
        }}
      />
      <button
        onClick={handleSaveNotes}
        className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        Save Notes
      </button>
    </div>
  );
};

export default Notes;

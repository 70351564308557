import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Layout = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const isActive = (path) => location.pathname === path;

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="flex items-center">
                  <img className="h-8 w-auto mr-2" src="/images/logo.png" alt="AI Guru Logo" />
                  <span className="text-2xl font-bold text-indigo-600">AI Guru</span>
                </Link>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <NavLink to="/" active={isActive('/')}>Home</NavLink>
                <NavLink to="/courses" active={isActive('/courses')}>Courses</NavLink>
                {isAuthenticated && (
                  <>
                    <NavLink to="/dashboard" active={isActive('/dashboard')}>Dashboard</NavLink>
                    <NavLink to="/profile" active={isActive('/profile')}>Profile</NavLink>
                  </>
                )}
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              {isAuthenticated ? (
                <button onClick={logout} className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Logout
                </button>
              ) : (
                <Link to="/login" className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {children}
      </main>
      <footer className="bg-white border-t border-gray-200 mt-12">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-gray-500 text-sm">
            © 2024 AI Guru. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

const NavLink = ({ to, children, active }) => {
  const baseClasses = "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
  const activeClasses = "border-indigo-500 text-gray-900";
  const inactiveClasses = "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";

  return (
    <Link to={to} className={`${baseClasses} ${active ? activeClasses : inactiveClasses}`}>
      {children}
    </Link>
  );
};

export default Layout;

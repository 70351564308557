import React, { useState, useEffect, useContext } from 'react';
import { fetchUserProfile, updateUserProfile, uploadProfilePicture } from '../services/api';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const Profile = () => {
  const { isInstructor, setIsInstructor } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    try {
      setIsLoading(true);
      const userData = await fetchUserProfile();
      setProfile(userData);
      await loadProfilePicture();
    } catch (err) {
      console.error('Error loading profile:', err);
      setError('Failed to load profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const loadProfilePicture = async () => {
    try {
      const response = await api.get('/user/profile-picture', { responseType: 'blob' });
      if (response.data instanceof Blob) {
        setProfilePictureUrl(URL.createObjectURL(response.data));
      } else if (response.data.url) {
        setProfilePictureUrl(response.data.url);
      }
    } catch (error) {
      console.error('Error loading profile picture:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProfile = await updateUserProfile(profile);
      setProfile(updatedProfile);
      setIsEditing(false);
      // Update the isInstructor status in the AuthContext if it has changed
      if (updatedProfile.isInstructor !== isInstructor) {
        setIsInstructor(updatedProfile.isInstructor);
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile. Please try again.');
    }
  };

  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      setError("File is too large. Maximum size is 5MB.");
      e.target.value = ""; // Clear the file input
      return;
    }
    try {
      setError(null);
      await uploadProfilePicture(file);
      await loadProfilePicture();
    } catch (err) {
      console.error('Error uploading profile picture:', err);
      setError('Failed to upload profile picture. Please try again.');
    }
  };

  if (isLoading) return <div>Loading profile...</div>;
  if (!profile) return <div>Failed to load profile. Please try again later.</div>;

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6">User Profile</h2>
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={profile.fullName || ''}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bio">
              Bio
            </label>
            <textarea
              id="bio"
              name="bio"
              value={profile.bio || ''}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="4"
            ></textarea>
          </div>
          {/* Add more editable fields as needed */}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save Changes
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div>
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Full Name</h3>
            <p>{profile.fullName}</p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Email</h3>
            <p>{profile.email}</p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Bio</h3>
            <p>{profile.bio}</p>
          </div>
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Role</h3>
            <p>{isInstructor ? 'Instructor' : 'Student'}</p>
          </div>
          {/* Display other profile fields */}
          <button
            onClick={() => setIsEditing(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Edit Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default Profile;
